<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize"> {{ $t('facial_recognition.name') }}</label>
          <b-form-input
            id="name"
            :value="nameFilter"
            @input="(val) => $emit('update:nameFilter', val)"
            trim
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <label class="text-capitalize">{{ $tc('unit.title', 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="unitFilter"
            :options="unitOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:unitFilter', val)"
          />
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <b-form-group>
            <label class="text-capitalize">Start Datetime</label>
            <flat-pickr
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s'}"
              :value="startDatetime"
              @on-change="(selectedDates, val) => $emit('update:startDatetime', val)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3" md="3" class="mb-md-0 mb-2">
          <b-form-group>
            <label class="text-capitalize">End Datetime</label>
            <flat-pickr
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s'}"
              :value="endDatetime"
              @on-change="(selectedDates, val) => $emit('update:endDatetime', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
} from "bootstrap-vue"
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    vSelect,
    flatPickr,
  },
  props: {
    nameFilter: {
      type: String,
      default: null,
    },
    unitFilter: {
      type: Array,
      default: null,
    },
    unitOptions: {
      type: Array,
      default: null,
    },
    startDatetime: {
      type: String,
      default: null,
    },
    endDatetime: {
      type: String,
      default: null,
    },
  },
};
</script>