import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);
  const toast = useToast();
  const personFilter = ref(null);
  const nameFilter = ref(null);
  const unitFilter = ref(null);
  const startDatetimeFilter = ref(null);
  const endDatetimeFilter = ref(null);
  const unitOptions = ref([]);
  axios("v1/unit?hasFacialRecognition=true&max=2000").then((response) => {
    unitOptions.value = response.data.data.map((unit) => ({
      label: unit.description,
      value: unit.id,
    }));
  });
  if (router.currentRoute.params.person_id)
    personFilter.value = router.currentRoute.params.person_id;
  const tableColumns = [
    {
      label: "date.created_at",
      key: "created_at",
      sortable: true,
      formatter: "formatDateAssigned",
    },
    { label: "facial_recognition.people_list", key: "id_luna_person_list", sortable: true },
    { label: "unit.title", key: "unit", sortable: false },
    { label: "facial_recognition.image", key: "image", sortable: false },
    { label: "facial_recognition.person_id", key: "descriptor", sortable: false },
    { label: "facial_recognition.name", key: "name", sortable: true },
    { label: "facial_recognition.age", key: "age", sortable: false },
    { label: "facial_recognition.gender", key: "gender", sortable: false },
    { label: "facial_recognition.wears_glasses", key: "mask", sortable: false },
    {
      label: "facial_recognition.capture_blur",
      key: "qualityBlurriness",
      sortable: false,
    },
    { label: "facial_recognition.capture_darkness", key: "qualityDark", sortable: false },
    { label: "facial_recognition.catch_light", key: "qualityLight", sortable: false },
    {
      label: "facial_recognition.captured_saturation",
      key: "qualitySaturation",
      sortable: false,
    },
    { label: "facial_recognition.captured_emotion", key: "emotion", sortable: false },
    { label: "facial_recognition.captured_ethnicity", key: "ethnicity", sortable: false },
    { label: "facial_recognition.catch_percentage", key: "score", sortable: false },
    { label: "facial_recognition.match_percentage", key: "similarity", sortable: true },
  ];
  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });
  const fetchList = (ctx, callback) => {
    store
      .dispatch("facial_recognition/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        personId: personFilter.value,
        name: nameFilter.value,
        unit: unitFilter.value,
        startDatetime: startDatetimeFilter.value,
        endDatetime: endDatetimeFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };
  const refetchData = () => {
    refListTable.value.refresh();
  };
  watch(
    [
      currentPage,
      perPage,
      nameFilter,
      unitFilter,
      startDatetimeFilter,
      endDatetimeFilter,
    ],
    () => {
      refetchData();
    }
  );

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    nameFilter,
    unitFilter,
    personFilter,
    unitOptions,
    startDatetimeFilter,
    endDatetimeFilter,
    refetchData,
  };
}
