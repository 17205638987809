<template>
  <div>
    <toastification-loading-content ref="loadingToast"/>
    <toastification-progress-content ref="progressToast"/>

    <!-- Breadcrumb -->
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <!-- Filters -->
    <filters
      :name-filter.sync="nameFilter"
      :unit-filter.sync="unitFilter"
      :unit-options="unitOptions"
      :start-datetime.sync="startDatetimeFilter"
      :end-datetime.sync="endDatetimeFilter"
    />

    <b-card-group deck :class="{ 'mx-2': showFilters, 'm-0': !showFilters }">
      <!-- Table Container Card -->
      <b-card class="m-2" no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>

            <!-- Search and Export-->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('table.search')"
                />
                <b-button
                  @click="exportExcel()"
                  variant="success"
                  class="text-capitalize"
                  type="button"
                  :disabled="buttonExport"
                >
                  <feather-icon icon="DownloadCloudIcon" size="15"/>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <!-- Table-->
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
          select-mode="single"
          selectable
        >
          <template #head()="data">
            <span> {{ $tc(data.label, 1) }}</span>
          </template>
          <template #cell(image)="data">
            <div class="hoverable">
              <b-img
                :src="`${data.item.descriptor}`"
                alt="path Image"
                style="max-height: 120px"
              ></b-img>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">{{
                  $t("table.showing", {
                    from: dataMeta.from,
                    to: dataMeta.to,
                    of: dataMeta.of,
                  })
                }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!-- Modal -->
      <b-card class="m-2" v-if="selectedObject" no-body>
        <feather-icon
          icon="XIcon"
          class="mr-2 ml-auto mt-2 float-rigth"
          style="cursor: pointer"
          size="30"
          @click="selectedObject = null"
        />
        <div class="mx-2">
          <div class="w-75 mx-auto">
            <div class="row">
              <div class="col-12 mb-2">
                <img
                  class="img-thumbnail"
                  :src="`${selectedObject.media.url}`"
                  alt=""
                />
              </div>
              <div class="col-6 text-center">
                <img
                  class="img-thumbnail"
                  :src="`${selectedObject.descriptor}`"
                  alt=""
                />
                <p class="my-0 mt-1">{{ $t('grid.slots.info.fr.face_detected') }}</p>
              </div>
              <div class="col-6 text-center">
                <img
                  class="img-thumbnail"
                  :src="`${selectedObject.avatar}`"
                  alt=""
                />
                <p class="my-0 mt-1">{{ $t('grid.slots.info.fr.enrolled_person') }}</p>
              </div>
            </div>
          </div>
          <table class="table table-sm">
            <tr>
              <th>{{ $t('grid.slots.info.fr.title') }}</th>
              <td>{{ formatDateAssigned(selectedObject.created_at) }}</td>
            </tr>
            <tr>
              <th>Nombre de la instalación:</th>
              <td>{{ selectedObject.unit }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.person_name') }}</th>
              <td>{{ selectedObject.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.list_name') }}</th>
              <td>{{ selectedObject.listName }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.age') }}</th>
              <td>{{ selectedObject.age }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.gender') }}</th>
              <td>{{ selectedObject.gender ? $t('grid.slots.info.fr.genderMale') : $t('grid.slots.info.fr.genderFemale') }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.emotion') }}</th>
              <td>{{ selectedObject.emotion }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.people_analytics.wearing_glasses') }}</th>
              <td>{{ selectedObject.glasses || 'não' }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.picture_quality') }}</th>
              <td>
                <table class="table table-sm">
                  <tr>
                    <th>{{ $t('grid.slots.info.fr.blurriness') }}</th>
                    <td>{{ formatPercentages(selectedObject.qualityBlurriness) }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('grid.slots.info.fr.darkness') }}</th>
                    <td>{{ formatPercentages(selectedObject.qualityDark) }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('grid.slots.info.fr.brightness') }}</th>
                    <td>{{ formatPercentages(selectedObject.qualityLight) }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('grid.slots.info.fr.saturation') }}</th>
                    <td>{{ formatPercentages(selectedObject.qualitySaturation) }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.score') }}</th>
              <td>{{ formatPercentages(selectedObject.score) }}</td>
            </tr>
            <tr>
              <th>{{ $t('grid.slots.info.fr.similarity') }}</th>
              <td>{{ formatPercentages(selectedObject.similarity) }}</td>
            </tr>
          </table>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import {
  BCard,
  BCardGroup,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref, onUnmounted, onMounted, computed, watch} from "@vue/composition-api";
import useList from "./useList";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent
  from "@core/components/toastification/ToastificationLoadingContent.vue";
import ToastificationProgressContent
  from "@core/components/toastification/ToastificationProgressContent.vue";
import store from "@/store";
import {useToast} from "vue-toastification/composition";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Filters from "./Filters";
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Pagination,
    BCardGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    Breadcrumb,
    ToastificationLoadingContent,
    ToastificationProgressContent,
    Filters,
    vSelect,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination
    // start breadcrumb and export
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.analytics.facial_recognition.luna.facial_recognition_matches");
    // end breadcrumb
    const selectedObject = ref(null);
    const buttonExport = ref(false);
    const loadingToast = ref(null);
    const progressToast = ref(null);
    const progress = computed(() => store.getters["facial_recognition/getProgress"]);
    const error = computed(() => store.getters["facial_recognition/getError"]);
    const url = computed(() => store.getters["facial_recognition/getUrl"]);
    store.dispatch("grid/findDownloadNotification", "facial-recognition").then((data) => {
      if (data.length > 0) {
        buttonExport.value = true;
        progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      }
    });
    const toast = useToast();
    const exportExcel = () => {
      buttonExport.value = true;
      store.dispatch("facial_recognition/resetProgress");
      progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      store
        .dispatch("facial_recognition/getAll", {
          q: searchQuery.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          name: nameFilter.value,
          unit: unitFilter.value,
          startDatetime: startDatetimeFilter.value,
          endDatetime: endDatetimeFilter.value,
          personId: personFilter.value,
          action: "export",
        })
        .then((response) => {
          store.dispatch(
            "facial_recognition/setExportFacialRecognition",
            response.data.jobId
          );
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error exportando la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          buttonExport.value = false;
          progressToast.value.close();
        });
    };
    watch([progress], () => {
      progressToast.value.updateProgress(progress.value);
    });
    watch([error], () => {
      toast({
        component: ToastificationContent,
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      buttonExport.value = false;
      progressToast.value.close();
    });
    watch([url], () => {
      if (url.value) {
        const link = document.createElement("a");
        link.href = url.value;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        buttonExport.value = false;
        progressToast.value.close();
      }
    });

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      nameFilter,
      personFilter,
      unitFilter,
      unitOptions,
      startDatetimeFilter,
      endDatetimeFilter,
    } = useList();

    onMounted(() => {
      window.Echo.connector.socket.on("subscription_error", (channel, data) => {
        console.log(channel, data);
      });
      if (!window.Echo.connector.socket.connected) window.Echo.connect();
      window.Echo.private(`facial-recognition`).listen(".create", (data) => {
        refetchData();
      });
    });
    onUnmounted(() => {
      window.Echo.leave(`facial-recognition`);
    });
    const onRowSelected = (item) => {
      selectedObject.value = item[0];
    };
    const formatPercentages = (num) => {
      return `${(parseFloat(num) * 100).toFixed(2)} %`;
    };
    return {
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      unitOptions,
      nameFilter,
      unitFilter,
      startDatetimeFilter,
      endDatetimeFilter,
      selectedObject,
      onRowSelected,
      formatPercentages,
      exportExcel,
      buttonExport,
      loadingToast,
      progress,
      progressToast,
      update
    };
  },
};
</script>


