
<template>
  <!-- toast -->
  <b-toast
    id="progress-toast"
    no-close-button
    no-auto-hide
    :variant="variant"
  >
    <template #toast-title >
      <div class="d-flex flex-grow-1 align-items-center mr-25">
        <strong class="mr-auto" v-text="title" />
        <!-- <small class="text-muted">11 mins ago</small> -->
      </div>
    </template>
    <span
      v-if="text"
      v-text="text"
    />
    <b-progress
      key="progress"
      animated
      :value="progress"
      variant="secondary"
      class="mt-1"
    />
  </b-toast>
</template>

<script>
import { BToast, BImg, BProgress } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BToast,
    BImg,
    BProgress,
  },
  directives: {
    Ripple,
  },
  setup(_, context) {
    const title = ref('')
    const text = ref('')
    const variant = ref('')
    const progress = ref(0)

    const show = (_title, _text, _variant) => {
      title.value = _title
      text.value = _text
      variant.value = _variant
      context.parent.$bvToast.show('progress-toast')
    }

    const close = () => {
      context.parent.$bvToast.hide('progress-toast')
    }

    const updateProgress = (pro) => {
      progress.value = pro
    }

    return {
      show,
      close,
      updateProgress,

      progress,
      title,
      text,
      variant
    }
  }
}
</script>
